<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">标题：</label>
				<el-input placeholder="请输入内容" style="width:90%;" v-model="newsdata.title"></el-input>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">封面图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" 
					:on-success="upload">
					<img v-if="newsdata.imgurl" :src="newsdata.imgurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="background-color: #fff;">
				<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
				<Editor style="height: 350px; overflow-y: hidden;" v-model="newsdata.content"
					:defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" @onChange="onChange"
					@onDestroyed="onDestroyed" @onMaxLength="onMaxLength" @onFocus="onFocus" @onBlur="onBlur"
					@customAlert="customAlert" @customPaste="customPaste" />
			</div>
			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="updatanews">编辑新闻</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				newsdata: {
					title: '',
					content: '',
					updatetime: '',
					adduser: '',
					imgurl: ''
				},
				editor: null,
				html: '',
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$ips + '/common/upfile',
							fieldName: 'file'
						},
						uploadVideo: {
							server: this.$ips + '/common/upfile',
							fieldName: 'file',
							maxFileSize: 100 * 1024 * 1024, // 100M
						}
					}
				},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			updatanews() {
				//  编辑新闻
				console.log(this.newsdata);
				if (this.newsdata.content == "<p><br></p>" || this.newsdata.title == '') {
					this.$message({
						showClose: true,
						message: '请填写新闻标题或内容',
						type: 'error'
					});
				} else if (this.newsdata.imgurl == '') {
					// 未上传图片
					this.$message({
						showClose: true,
						message: '请上传新闻封面图片',
						type: 'error'
					});
				} else {
					let that = this
					this.$confirm('确定要编辑吗', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then((res1) => {
						console.log(res1);
						if(res1 == 'confirm'){
							console.log('确认');
							that.newsdata.updatetime = that.$gettime()
							that.$post("/news/updatanews",that.newsdata).then(res=>{
								if(res.data=='编辑成功'){
									that.$message({
										type: 'success',
										message: '编辑成功!'
									});
								}else{
									that.$message({
										type: 'error',
										message: '编辑失败!'
									});
								}
							})
						}else{
							console.log('取消');
							this.$message({
								type: 'info',
								message: '已取消'
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			upload(e) {
				console.log(e);
				if (e.data.url) {
					this.newsdata.imgurl = e.data.url
				}
			},
			onCreated(editor) {
				this.editor = Object.seal(editor)
				console.log('onCreated', editor)
			},
			onChange(editor) {
				console.log('onChange', editor.children)
			},
			onDestroyed(editor) {
				console.log('onDestroyed', editor)
			},
			onMaxLength(editor) {
				console.log('onMaxLength', editor)
			},
			onFocus(editor) {
				console.log('onFocus', editor)
			},
			onBlur(editor) {
				console.log('onBlur', editor)
			},
			customAlert(info, type) {
				window.alert(`customAlert in Vue demo\n${type}:\n${info}`)
			},
			customPaste(editor, event, callback) {
				console.log('ClipboardEvent 粘贴事件对象', event)
				const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
				const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
				// const rtf = event.clipboardData.getData('text/rtf')
				// 获取 rtf 数据（如从 word wsp 复制粘贴）
				// 自定义插入内容
				editor.insertText(text)
				// 返回 false ，阻止默认粘贴行为
				event.preventDefault()
				// callback(false) // 返回值（注意，vue 事件的返回值，不能用 return）
				// 返回 true ，继续默认的粘贴行为
				callback(true)
			}
		},
		mounted() {
			
			this.$get('/news/getnews',{
				id:this.$route.params.id
			}).then(res=>{
				this.newsdata = res.data[0]
			})
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>